<template>
  <div class="d-flex align-items-center">
    <slot
      name="content"
      :is-read-only="isReadOnly || !content"
    />
    <b-icon
      v-if="!isReadOnly"
      :icon="!content ? 'shield-lock' : 'shield-check'"
      :variant="'primary'"
      :data-testid="`auth_toggle--${id}`"
      font-scale="1.5"
      :class="`cursor-pointer ml-1`"
      :title="!content
        ? $t('Clique para habilitar a edição do campo')
        : $t('Campo habilitado para edição')
      "
      @click="onAuthorizeAction"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { hasPermissions } from '@/auth/utils'

export default {
  components: {},

  mixins: [],

  props: {
    id: {
      type: String,
      default: "input",
    },

    value: {
      type: [String, Object],
      default: null,
    },

    delegablePermission: {
      type: Object,
      required: true,
    },
    storeId: {
      type: [Number, String],
      required: true,
    },
    isValidStore: {
      type: Boolean,
      required: true,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: null,
    }
  },

  computed: {
    ...mapState('app', ['modalAuthRef']),
  },

  watch: {
    value(val) {
      this.content = val
    },
  },

  created() {
    // necessário para preencher o input com base no valor que vem da prop "value"
    this.content = this.value
  },

  methods: {
    async onAuthorizeAction() {
      if (this.content) {
        this.showSuccess({
          title: this.$t('Edição já está autorizada'),
        })
        return
      }

      if (!this.isValidStore) {
        this.showWarning({
          message: this.$t('Uma loja deve ser selecionada para habilitar este campo.'),
        })
        return
      }

      try {
        if (hasPermissions(this.delegablePermission.permission)) {
          this.content = { alreadyHasPermission: true }
          this.handleInput()
          return
        }

        const delegated = await this.modalAuthRef.auth(this.delegablePermission, this.storeId)

        this.content = delegated
        // necessário para atualizar a propriedade "value" que vem de uma v-model.
        this.handleInput()
      } catch (error) {
        this.content = null
        this.handleInput()
        if (error.message) {
          this.showWarning({ message: error.message })
        }
      }
    },
    handleInput() {
      this.$emit('input', this.content)
    },
  },
}
</script>
